<template>
  <div class="projectOverviewJD" :class="bgimg">
    <!-- 上下结构设计，底部固定高度，顶部自适应 -->
    <div class="header">
      <!-- 顶部左中右结构，flex布局，中间自适应 -->
      <div class="h-left">
        <div class="heat-box census" v-loading="status.censusStatus">
          <div class="heat-title">
            <img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
            <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">
            <span>综合能耗统计</span>
          </div>
          <div class="census-body">
            <div class="census-left">
              <div class="census-left-number">
                <div class="c-l-n-value">
                  {{yzhnh.toFixed(1)}}
                </div>
                <div class="c-l-n-name">tce</div>
              </div>
              <div class="census-left-name">
                <div class="census-left-name-value">月综合能耗</div>
                <!--<div class="census-left-name-img"></div> -->
              </div>
            </div>
            <div class="census-variety">
              <div class="census-variety-item">
                <div
                  class="c-variety-item-number bottom"
                >
                  <i
                    class="el-icon-caret-bottom"
                  ></i>
                  -5.3%
                </div>
                <div class="c-variety-item-time">同比{{ yoyData2 }}</div>
              </div>
              <div class="census-variety-item">
                <div
                  class="c-variety-item-number top"
                >
                  <i
                    class="el-icon-caret-top"
                  ></i>
                  5.6%
                </div>
                <div class="c-variety-item-time">环比{{ qoqData2 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="heat-box analysis" v-loading="status.analysis">
          <div class="heat-title"><img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
            <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">单位能耗分析</div>
          <div class="analysis-body">
            <div class="analysis-item">
              <div class="analysis-title">单位面积电耗</div>
              <div class="analysis-item-center">
                <div class="analysis-left square"></div>
                <div class="analysis-right">
                  <div class="analysis-right-num">
                    108.1
                  </div>
                  <div>kWh/㎡·a</div>
                </div>
              </div>
            </div>
            <div class="analysis-item">
              <div class="analysis-title">单位房间水耗</div>
              <div class="analysis-item-center">
                <div class="analysis-left user"></div>
                <div class="analysis-right">
                  <div class="analysis-right-num">
                    759.2
                  </div>
                  <div class="analysis-right-val">L/间·d</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="heat-box pattern">
          <div class="heat-title"><img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
            <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">能耗营收分析
            </div>
          <div class="energyanalysisTitle">
            <img src="~@/assets/projectOverallJD/03.webp" alt="" class="logo">
            年平均营收比<span>{{allnum}}</span>%
          </div>
          <div class="echartCont">
            <div id="energyanalysisEchart"></div>
          </div>
        </div>
      </div>
      <div class="h-center">
        <img
          class="center-header"
          src="~@/assets/projectOverallJD/head.png"
          alt=""
        />
        <div class="center-list" v-loading="status.loadCenterData">
          <div class="center-item">
            <div class="center-val">
              <!-- {{ getMonitorRealtimeData }}<span>KW</span> -->
              {{nzhn.toFixed(1)}}<span>万元</span>
            </div>
            <!-- <div class="center-name">实时负荷</div> -->
            <div class="center-name">年总能耗</div>
          </div>
          <div class="center-item">
            <div class="center-val">
              <!-- {{
                censusDataAll.value
                  ? (censusDataAll.value / 1000).toFixed(2)
                  : "--"
              }}<span>tce</span> -->
              1405.6<span>kW</span>
            </div>
            <!-- <div class="center-name">累计节能(标煤)</div> -->
            <div class="center-name">实时负荷</div>
          </div>
          <div class="center-item">
            <div class="center-val">
              <!-- {{
                totalSavingFeeData
                  ? (totalSavingFeeData / 10000).toFixed(2)
                  : "--"
              }}<span>万元</span> -->
              25.8<span>°C</span>
            </div>
            <!-- <div class="center-name">累计节费(万元)</div> -->
            <div class="center-name">室内温度</div>
          </div>
          <div class="center-item">
            <div class="center-val">
              <!-- {{
                totalReductionData
                  ? (totalReductionData / 10000).toFixed(2)
                  : "--"
              }}<span>吨</span> -->
              45.5<span>%</span>
            </div>
            <!-- <div class="center-name">累计减排(CO₂)</div> -->
            <div class="center-name">室内湿度</div>
          </div>
        </div>
        <div class="center-foot"></div>
      </div>
      <div class="h-right">
        <div class="heat-box reduce" v-loading="status.reduceData">
          <div class="heat-title"><img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
            <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">节能总览</div>
          <div class="reduce-body">
            <div class="reduce-left">
              <div class="reduce-number">
                <!-- {{
                  reduceData.saving
                    ? (reduceData.saving / 1000).toFixed(2)
                    : "-- --"
                }}<span>tce</span> -->
                79.2<span>万元</span>

              </div>
              <div class="reduce-name">节能费用</div>
            </div>
            <div class="reduce-right">
              平均节能率
              <!-- <span>{{
                reduceData.rate ? reduceData.rate * 100 + "%" : "-- --"
              }}</span> -->
              <span>12<span>%</span></span>
            </div>
          </div>
        </div>
        <div class="heat-box zhineng">
            <div class="heat-title"><img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
                <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">智能模式
            </div> 
            <div class="pattern-body">
            <div class="pattern-item stop" @click="goColdSystem">
              <div class="pattern-left"></div>
              <div class="pattern-name">制冷系统</div>
              <div class="pattern-right">
                <img
                  v-if="coldData != 1"
                  src="~@/assets/heatingSystemWebp/冷冻投用2倍图.webp"
                  alt=""
                  style="cursor: pointer;"
                />
                <img v-else src="~@/assets/heatingSystem/停用.png" alt="" style="cursor: pointer;"/>
                <div class="pattern-right-bg"></div>
              </div>
              <!-- <div class="pattern-icon"></div> -->
              <div class="pattern-val">
                <span>{{ coldData != 1 ? "寻优" : "手动" }}</span>
              </div>
              <div class="jineneg-number">
                <div class="number">
                    <i class="el-icon-caret-top"></i>
                    <span>16.2%</span>
                </div>
                <div class="txt">
                    累计节能率
                </div>
              </div>
              <img
                class="icon"
                v-if="coldData != 1"
                src="~@/assets/heatingSystemWebp/引导按钮.webp"
                alt=""
              />
              <img
                class="icon"
                v-else
                src="~@/assets/heatingSystem/stop-iocn.png"
                alt=""
              />
            </div>
            <div class="pattern-item start" @click="goHeatingSystem">
              <div class="pattern-left"></div>
              <div class="pattern-name">采暖系统</div>
              <div class="pattern-right">
                <!-- <img
                  v-if="hotData != 1"
                  src="~@/assets/heatingSystemWebp/采暖投用2倍图.webp"
                  alt=""
                /> -->
                <img src="~@/assets/heatingSystem/停用2.png" alt="" />
                <div class="pattern-right-bg"></div>
              </div>
              <!-- <div class="pattern-icon"></div> -->
              <div class="pattern-val">
                <!-- <span>{{ hotData != 1 ? "寻优" : "手动" }}</span> -->
                <span>手动</span>
              </div>
              <div class="jineneg-number">
                <div class="number down">
                    <i class="el-icon-caret-bottom"></i>
                    <span>--</span>
                </div>
                <div class="txt">
                    累计节能率
                </div>
              </div>
              <img
                class="icon"
                v-if="hotData != 1"
                src="~@/assets/heatingSystemWebp/引导按钮2.webp"
                alt=""
              />
              <img
                class="icon"
                v-else
                src="~@/assets/heatingSystem/start-icon.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="heat-box jianpai">
            <div class="heat-title"><img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
                <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">减排量
            </div> 
            <div class="analysisStyle">
              <div>
                <div class="itemcont">
                  <img src="~@/assets/projectOverallJD/05.webp" alt="" class="analimg">
                  <div class="analysisTxt">
                    <span class="txt1">2022年</span>
                    <span class="txt2">CO₂减排 (t) </span>
                    <span class="txt3">323.2</span>
                  </div>
                </div>
                <div class="itemcont seconditem">
                  <img src="~@/assets/projectOverallJD/04.webp" alt="" class="analimg">
                  <div class="analysisTxt">
                    <span class="txt1">2022年</span>
                    <span class="txt2">节约标煤(tce)</span>
                    <span class="txt3">231.7</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- <div class="heat-box month">
          <div class="heat-title">
            <img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
            <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">
            <span>能源成本</span>
          </div>
          <div class="heat-body month-body">
            <div class="month-title">
              <div class="month-title-number yl-f-column">
                <div class="yl-f-column-num">628</div>
                <div class="yl-f-column-name">总计用能成本(万元)</div>
              </div>
              <div class="month-title-2 yl-f-column">
                <div class="yl-f-column-num down">-13%</div>
                <div class="yl-f-column-name">同比</div>
              </div>
              <div class="month-title-3 yl-f-column">
                <div class="yl-f-column-num up">+0.9%</div>
                <div class="yl-f-column-name">环比</div>
              </div>
            </div>
            <div
              style="width: 100%; height: calc(50% - 28px); margin-bottom: 10px"
            >
              <v-chart ref="chartMonth" :option="optionMonth" />
            </div>
            <div style="width: 100%; height: calc(50% - 28px)">
              <v-chart ref="chartCdm" :option="optionCdm" />
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="floor">
      <div class="heat-box used" v-loading="status.loadUserd">
        <div class="heat-title"><img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
            <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">能耗指标使用情况</div>
        <div class="heat-body used-body">
          <div class="used-item">
            <div class="item-header">用电量(kWh)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used0.webp"
              alt=""
            />
            <div class="item-num">
              25634.2
            </div>
          </div>
          <div class="used-item">
            <div class="item-header">用水量(t)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used1.webp"
              alt=""
            />
            <div class="item-num">
              <!-- {{ usedData.water === null ? "-- --" : usedData.water }} -->
              286.5
            </div>
          </div>
          <div class="used-item">
            <div class="item-header">燃气用量(m³)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used2.webp"
              alt=""
            />
            <div class="item-num">
              {{gasnum.toFixed(1)}}
            </div>
          </div>
          <div class="used-item">
            <div class="item-header">用热量(kWh)</div>
            <!-- 第二个页面热水接口 -->
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used3.webp"
              alt=""
            />
            <div class="item-num">
              {{heatcon}}
            </div>
          </div>
          <div class="used-item">
            <div class="item-header">用冷量(kWh)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used4.webp"
              alt=""
            />
            <div class="item-num">
              {{coolcon}}
            </div>
          </div>
          <div class="used-item">
            <div class="item-header">绿色能源(kWh)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used5.webp"
              alt=""
            />
            <div class="item-num">
              811.2
            </div>
          </div>
          <!-- <div class="used-item">
            <div class="item-header">绿色能源(KWh)</div>
            <img
              class="item-icon"
              src="~@/assets/heatingSystemWebp/used5.webp"
              alt=""
            />
            <div class="item-num">{{ usedData.cold===null?'-- --':usedData.water }}</div>
          </div> -->
        </div>
      </div>
      <div class="heat-box multiple" v-loading="status.comprehensiveData">
        <div class="heat-title">
            <img class="leftIcon" src="~@/assets/projectOverallJD/标题左.png">
            <img class="rightIcon" src="~@/assets/projectOverallJD/标题右.png">
          <span>综合能耗分析</span>
          <div class="multiple-title">
            <div
              v-for="(item,index) in multipleArr"
              :key="item.energyTypeId"
              class="multiple-title-item"
              :class="{ active: multipleIndex == item.energyTypeId }"
              @click="changeMultipleIndex(item.energyTypeId,index)"
            >
              {{ item.energyTypeName }}
            </div>
          </div>
        </div>
        <div class="heat-body multiple-body">
          <v-chart ref="chartMultiple" :option="optionMultiple" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

var date = new Date();
import * as echarts from "echarts";
import * as dayjs from "dayjs";
import { mapState } from "vuex";

import {
  screenQuery,
  getDayElecUse,
  getDayColdUse,
  getDayAirUse,
  // getDayHeatUse,
  getDayWaterUse,
  getOverallCOP,
  // getShowTypes,
  periodDiff,
  getMonitorRealtime,
  monthData,
  // useEnergySearch,
  yearEnergySaving,
  totalSavingFee,
  totalReduction,
  comprehensiveData,
  comprehensiveArea,
  comprehensivePerson,
  multipleQuery,
  getDiffData,
} from "@/request/apiLg";
export default {
  name: "projectOverview",
  props: {
    bgimg: {
      type: String,
      default: "bg1",
    },
    projectCode: {
      type: String,
      default: "zgc",
    },
  },
  data() {
    return {
      coolcon:0,
      heatcon:0,
      gasnum:0,
      optionBase: {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          formatter: "{b}: {c}万元",
        },
        legend: {},
        grid: {
          top: "15px",
          left: "0",
          right: "40px",
          bottom: "15px",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          name: "万元",
          // 轴上的刻度设置
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#fff",
            },
          },
          // 轴线样式设置
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          // 轴上延申线
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63, 109, 248, 0.5)"],
              width: 1,
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          // 单位设置
          nameTextStyle: {
            color: "#fff",
            verticalAlign: "top",
            align: "left",
          },
        },
        yAxis: {
          type: "category",
          data: [],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisLabel: {
            color: "red",
            inside: false,
            textStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: "red",
              widtg: 1,
              type: "dashed",
            },
          },
        },
        series: [],
      },
      optionMultiple: {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          textStyle: {
            color: "#fff",
          },
          itemWidth:20,
          itemHeight:10
        },
        grid: {
          top: "35px",
          left: "20px",
          right: "50px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          name: "kgce",
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00",
          ],
          // 轴上的刻度设置
          axisLabel: {
            inside: false,
            interval: 0,
            textStyle: {
              color: "#fff",
            },
          },
          // 轴线样式设置
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisTick: {
            show: false,
          },
          // 单位设置
          nameTextStyle: {
            color: "#fff",
            verticalAlign: "top",
            align: "left",
          },
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          // 轴上延申线
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: ["rgba(63, 109, 248, 0.5)"],
              width: 1,
              type: "dashed",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisLabel: {
            color: "red",
            inside: false,
            textStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: "red",
              widtg: 1,
              type: "dashed",
            },
          },
        },
        series: [],
      },
      optionCdm: {},
      optionMonth: {},
      multipleArr: [],
      multipleIndex: "0",
      status: {
        censusStatus: true,
        analysis: true,
        comprehensiveData: true,
        reduceData: true,
        loadUserd: true,
        loadCenterData: true,
      },
      censusData: {},
      censusDataAll: {},
      yoyData2:dayjs().subtract(1,'year').format('YYYY年MM月'),
      qoqData2: dayjs().subtract(1,'month').format('YYYY年MM月'),
      yoyData: {},
      qoqData: {},
      analysisData: {},
      reduceData: {},
      usedData: {},
      periodDiffQuery: {
        endTime: dayjs()
          .subtract(4, "month")
          .endOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        startTime: dayjs()
          .subtract(4, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
      },
      periodDiffQueryAll: {
        endTime: dayjs()
          .subtract(4, "month")
          .endOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        startTime: "2022-01-01 00:00:00",
      },
      coldData: "",
      hotData: "",
      getMonitorRealtimeData: "",
      // 本月的数据
      comprehensiveAreaQuery: {
        endTime: dayjs().subtract(4, "month").endOf("month").format("YYYY-MM-DD 00:00:00"),
        startTime: dayjs()
          .subtract(4, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
      },
      monthDataQueryYoy: {
        compareEndTime: dayjs()
          .add(1, "days")
          .subtract(4, "month")
          .subtract(1, "year")
          .format("YYYY-MM-DD 00:00:00"),
        compareStartTime: dayjs()
          .subtract(1, "year")
          .subtract(4, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs()
          .subtract(4, "month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00"),
        pointId: "",
        startTime: dayjs()
          .subtract(4, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        timeUnit: "MONTHS",
      },
      monthDataQueryQoq: {
        compareEndTime: dayjs()
          .add(1, "days")
          .subtract(4, "month")
          .format("YYYY-MM-DD 00:00:00"),
        compareStartTime: dayjs()
          .subtract(4, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        endTime: dayjs()
          .subtract(4, "month")
          .add(1, "days")
          .format("YYYY-MM-DD 00:00:00"),
        pointId: "",
        startTime: dayjs()
          .subtract(4, "month")
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00"),
        timeUnit: "MONTHS",
      },
      useEnergyQuery: {
        endTime: dayjs()
          .subtract(4, "month")
          .add(1, "day")
          .format("YYYY-MM-DD 00:00:00"),
        energyTypeId: "",
        interval: 1,
        projectId: "bjzgc",
        startTime: dayjs().subtract(4, "month").format("YYYY-MM-DD 00:00:00"),
        timeUnit: "HOURS",
      },
      useEnergyQueryAll: {
        endTime: dayjs()
          .subtract(4, "month")
          .add(1, "day")
          .format("YYYY-MM-DD 00:00:00"),
        startTime: dayjs().subtract(4, "month").format("YYYY-MM-DD 00:00:00"),
        timeUnit: "HOURS",
        pointId: "",
        tomorrowEndTime: dayjs()
          .subtract(4, "month")
          .format("YYYY-MM-DD 00:00:00"),
        tomorrowStartTime: dayjs()
          .subtract(4, "month")
          .subtract(2, "day")
          .format("YYYY-MM-DD 00:00:00"),
      },
      yearTotalQuery: {
        endTime: dayjs().subtract(4, "month").format("YYYY-MM-DD HH:mm:ss"),
        startTime: dayjs()
          .subtract(4, "month")
          .startOf("year")
          .format("YYYY-MM-DD 00:00:00"),
      },
      totalSavingFeeData: "",
      totalReductionData: "",
      // 综合能耗统计点
      "energy.consume.yoy.point": "",
      "energy.use.hot.water": "",
      "hot.run.mode": "",
      "energy.electric.point.p": "",
      dataarr:{
        zh:{
         today:[
          0.41,0.42,0.4,0.43,0.44,0.43,
          0.44,0.46,0.49,0.53,0.53,0.57,
          0.58,0.55,0.56,0.57,0.58,0.57,
          0.56,0.55,0.52,0.48,0.47,0.46],
         yesterday:[
          0.42,0.43,0.42,0.44,0.43,0.44,
          0.44,0.47,0.51,0.54,0.56,0.58,
          0.59,0.58,0.56,0.59,0.60,0.58,
          0.57,0.56,0.63,0.50,0.48,0.47]
        },
        d:{
          today:[
            820,830,825,820,825,860,
            930,990,1050,1100,1150,1100,
            1080,1020,1060,1100,1040,1060,
            1010,970,940,910,880,860
          ],
          yesterday:[
            830,840,845,840,865,880,
            970,1010,1080,1150,1200,1160,
            1140,1120,1090,1120,1080,1100,
            1040,990,960,920,880,870
          ]
        },
        rq:{
          today:[
            0,0,0,0,0,0,0,0,0,0,0,0,
            0,0,0,0,0,0,0,0,0,0,0,0
          ],
          yesterday:[
            0,0,0,0,0,0,0,0,0,0,0,0,
            0,0,0,0,0,0,0,0,0,0,0,0
          ]
        }
      },
      rqdataArr: {
        zlj:{
          today:[3.2,2,2.4,2,3.2,2.8,11.2,19.6,18.8,12.8,8.4,
          20.4,19.2,7.2,13.6,17.2,20.4,20.8,20,33.6,32.8,39.2,43.6,7.6
          ],
          yesterday:[3.6,2.2,2.6,2.2,3.5,2.9,11.6,19.7,18.9,12.9,8.8,
          20.6,19.8,7.5,13.8,17.5,20.7,20.9,20.4,33.8,32.9,39.4,43.9,7.8
          ]
        },
        gdj:{
          today:[1.8,3.2,3.2,2.1,4.5,3.2,13.2,20.3,19.3,9.8,10.4,18.9,22.5,13.2,
          13.6,17.2,20.4,20.8,20,33.6,32.8,39.2,43.6,7.6
          ],
          yesterday:[
            1.9,3.4,3.3,2.6,4.7,3.5,13.7,20.4,19.6,9.9,10.6,19.2,22.7,13.6,
          13.7,17.5,20.6,20.9,20.3,33.8,32.9,39.4,43.8,7.8
          ]
        },
        zrj:{
          today:[101.8,103.2,103.2,102.1,104.5,103.2,113.2,120.3,119.3,109.8,110.4,
          118.9,122.5,113.2,113.6,117.2,120.4,120.8,120,133.6,132.8,139.2,143.6,107.6
          ],
          yesterday:[
            102.2,103.5,103.9,102.8,104.9,103.7,113.9,120.8,119.9,110.2,110.9,
          119.9,122.9,113.7,114.6,117.9,121.4,121.8,120.8,134.2,133.6,139.9,143.9,108.2
          ]
        }
      },
      yzhnh:221.8 / (new Date(date.getFullYear(),date.getMonth() + 1,0)).getDate()  * date.getDate(),
      nzhn: 530 / 12 * (date.getMonth() + 1),
      nhysjn:[8.4,8.2,8.3,8.1,7.7,7.6,8.2,8.5,8.3,7.7,7.9,8.2],
      nhysqn:[8.3,8.5,8.2,8,7.6,7.7,8.3,8.4,8.2,7.6,7.9,8]
    };
  },
  components: {},
  methods: {
    energyanalysisEchart() {
      console.log('this.$echarts',this.$echarts)
      const curveEchart = this.$echarts.init(
        document.getElementById('energyanalysisEchart')
      )
      curveEchart.setOption(
        {
          color: ['#22FFCC', '#00dbff'],
          xAxis: {
            type: 'category',
            data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            boundaryGap:false,
            alignTicks: true,
            axisTick: {
              show: false,
            },
            axisLabel:{
              interval:0,
              color:'rgba(255,255,255,0.8)'

            }
            // axisLine:{
            //   lineStyle:{
            //     color:'#999999',
            //     type:'dashed'
            //   }
            // }
            // minInterval: 1,
            // maxInterval: 10
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          yAxis: {
            type: 'value',
            name: '%',
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(153, 153, 153, 0.32)"],
                width: 1,
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "rgba(255, 255, 255, 0.8)",
              },
            },
            min:7.5,
            max:8.5
          },
          series: [
            {
              name: '去年',
              type: 'line',
              // smooth: true,
              lineStyle: {
                width: 1
              },
              showSymbol: false,
              // areaStyle: {
              //   opacity: 0.8,
              //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //     {
              //       offset: 0,
              //       color: '#0066D2'
              //     },
              //     {
              //       offset: 1,
              //       color: '#0066D200'
              //     }
              //   ])
              // },
              emphasis: {
                focus: 'series'
              },
              data: this.nhysqn
            },
            {
              name: '当年',
              type: 'line',
              // smooth: true,
              lineStyle: {
                width: 1
              },
              showSymbol: false,
              // areaStyle: {
              //   opacity: 0.8,
              //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //     {
              //       offset: 0,
              //       color: '#19E3B4'
              //     },
              //     {
              //       offset: 1,
              //       color: '#ffffff00'
              //     }
              //   ])
              // },
              emphasis: {
                focus: 'series'
              },
              // data: this.setmonthdata(this.nhysjn)
              data: this.nhysjn.map((item,index)=>{
                if(date.getMonth()> index){
                  return item
                }else{
                  return null
                }
              })
            },
            
          ],
          legend: {
            // data: this.legenddata,
            //   orient: 'vertical'
            // top: 'top',
            itemWidth:20,
            itemHeight:10,
            bottom:10,
            textStyle: {
            color: "#fff",
            
          },
          }
        },
        true
      )
    },
    loadEcharts() {
      // 当月能源成本
    //   this.loadChartCdm();
    //   this.loadChartMonth();
    },
    loadChartCdm() {
      this.optionCdm = {
        ...this.optionBase,
        tooltip: {
          trigger: "axis",
          backgroundColor: "#031540",
          borderColor: "#3F6DF8",
          textStyle: {
            color: "#bcccff",
          },
          formatter: function (params) {
            let res = "";
            if (params[0].value !== 0) {
              res += `预计${params[0].name}：${params[0].value}万元<br/>`;
            } else if (params[1].value !== 0) {
              res += `${params[1].name}：${params[1].value}万元<br/>`;
            }
            return res;
          },
        },
        xAxis: [
          {
            type: "value",
            name: "万元",
            // 轴上的刻度设置
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#fff",
              },
            },
            // 轴线样式设置
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.5)"],
                width: 1,
                type: "dashed",
              },
            },
            axisTick: {
              show: false,
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "left",
            },
          },
          {
            type: "value",
            name: "万元",
            // 轴上的刻度设置
            axisLabel: {
              inside: false,
              textStyle: {
                color: "#fff",
              },
            },
            // 轴线样式设置
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(63, 109, 248, 0.8)",
              },
            },
            // 轴上延申线
            splitLine: {
              lineStyle: {
                // 使用深浅的间隔色
                color: ["rgba(63, 109, 248, 0.5)"],
                width: 1,
                type: "dashed",
              },
            },
            axisTick: {
              show: false,
            },
            // 单位设置
            nameTextStyle: {
              color: "#fff",
              verticalAlign: "top",
              align: "left",
            },
          },
        ],
        yAxis: {
          type: "category",
          data: ["节省水费", "节省燃气", "节省电费"],
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(63, 109, 248, 0.8)",
            },
          },
          axisLabel: {
            color: "red",
            inside: false,
            textStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: "red",
              widtg: 1,
              type: "dashed",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 8,
            stack: "Ad",
            data: [28, 0, 49], //数据
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(150, 150, 150, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " rgba(150, 150, 150, 0.55)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(150, 150, 150, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " rgba(150, 150, 150, 0.55)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
            },
          },
          {
            type: "bar",
            barWidth: 8,
            stack: "Ad",
            data: [0, 35, 0], //数据
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(63, 109, 248, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " #55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(63, 109, 248, 0.35)" }, //柱图渐变色
                  { offset: 1, color: " #55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
            },
          },
        ],
      };
      this.$refs.chartCdm.setOption(this.optionCdm);
    },
    loadChartMonth() {
      this.optionMonth = {
        ...this.optionBase,
        series: [
          {
            type: "bar",
            barWidth: 8,
            data: [160, 199, 270], //数据
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(210, 172, 73, 0.35)" }, //柱图渐变色
                  { offset: 1, color: "rgba(210, 172, 73, 1)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: "rgba(210, 172, 73, 0.8)" }, //柱图渐变色
                  { offset: 1, color: "rgba(210, 172, 73, 1)" }, //柱图渐变色
                ]),
                barBorderRadius: 7,
              },
            },
          },
        ],
      };
      this.optionMonth.yAxis.data = ["用水成本", "用气成本", "用电成本"];
      this.$refs.chartMonth.setOption(this.optionMonth);
    },
    loadMultiple(firstData, lastData) {
      console.log('当前时间',new Date().getHours())
      let num = new Date().getHours() + 1
      console.log(firstData.map((item,index)=>{
        if(index < num){
          return item
        }else{
          return null
        }
      }))
      let optionMultiple = {
        ...this.optionMultiple,
        series: [
          {
            barWidth: 8,
            name: "今天",
            type: "bar",
            data: firstData.map((item,index)=>{
        if(index < num){
          return item
        }else{
          return null
        }
      }),
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#47A5E1" }, //柱图渐变色
                  { offset: 1, color: "#55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#55FFBC" }, //柱图渐变色
                  { offset: 1, color: "#55FFBC" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
          {
            name: "昨天",
            type: "bar",
            barWidth: 8,
            data: lastData,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#3F6DF8" }, //柱图渐变色
                  { offset: 1, color: "#4AB4F8" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
              emphasis: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#4AB4F8" }, //柱图渐变色
                  { offset: 1, color: "#4AB4F8" }, //柱图渐变色
                ]),
                barBorderRadius: [5, 5, 0, 0],
              },
            },
          },
        ],
      };
      if (this.multipleIndex === this.multipleArr[1].energyTypeId) {
        optionMultiple.xAxis.name = "kWh";
      } else if (this.multipleIndex === this.multipleArr[2].energyTypeId) {
        optionMultiple.xAxis.name = "m³";
      } else {
        optionMultiple.xAxis.name = "tce";
      }
      console.log("this.multipleIndex", this.multipleIndex);
      this.$refs.chartMultiple.setOption(optionMultiple);
      this.status.comprehensiveData = false;
    },
    periodDiff() {
      // 综合能耗统计
      periodDiff({
        ...this.periodDiffQuery,
        pointId: this["energy.consume.yoy.point"],
      }).then((res) => {
        if (res.data.code == 0) {
          this.censusData.value = res.data.data.formatValue;
          this.status.censusStatus = false;
        }
      });
    },
    screenQuery() {
      // 节能率
      screenQuery("energy.saving.rate").then((res) => {
        if (res.data.code == 0) {
          this.reduceData.rate = res.data.data.value;
        }
      });
      // 采暖系统-制冷
      screenQuery("cold.run.mode").then((res) => {
        if (res.data.code == 0) {
          this.coldData = res.data.value;
        }
      });
    },
    multipleQuery() {
      return new Promise((resolve, reject) => {
        multipleQuery([
          "energy.saving.rate",
          "cold.run.mode",
          "total.energy.consume.point.id",
          "energy.gas.point",
          "energy.electric.point",
          "energy.electric.point.p",
          "energy.consume.yoy.point",
          "energy.use.hot.water",
          "hot.run.mode",
        ])
          .then((res) => {
            if (res.data.code === 0) {
              let data = res.data.data;
              this.reduceData.rate = data["energy.saving.rate"].value;
              this.coldData = data["cold.run.mode"];
              this.multipleArr = [
                {
                  energyTypeId: data["total.energy.consume.point.id"].value,
                  energyTypeName: "综合",
                },
                {
                  energyTypeId: data["energy.electric.point"].value,
                  energyTypeName: "电",
                },
                {
                  energyTypeId: data["energy.gas.point"].value,
                  energyTypeName: "燃气",
                },
              ];
              this.multipleIndex = data["total.energy.consume.point.id"].value;
              // 综合能耗的点
              this["energy.consume.yoy.point"] =
                data["energy.consume.yoy.point"].value;
              // 能耗使用情况-热水量
              this["energy.use.hot.water"] = data["energy.use.hot.water"].value;
              this["hot.run.mode"] = data["hot.run.mode"].value;
              this["energy.electric.point.p"] =
                data["energy.electric.point.p"].value;

              // this.useEnergySearch();
              this.loadMultiple(this.dataarr.zh.today, this.dataarr.zh.yesterday);
              let month = new Date().getMonth() + 1
              if([6,7,8,9].indexOf(month)){
                this.coolcon = 19034.9
                this.heatcon = 0
                this.gasnum = this.sum(this.rqdataArr.zlj.today)
              }else if([1,12].indexOf(month)){
                this.coolcon = 0
                this.heatcon = 15813.3
                this.gasnum = this.sum(this.rqdataArr.zrj.today)
              }else{
                this.coolcon = 0
                this.heatcon = 0
                this.gasnum = this.sum(this.rqdataArr.gdj.today)
              }
              resolve(true);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadAnalysis() {
      // 单位能耗分析
      // 单位面积总和能耗 综合能耗*1000/面积
      // screenQuery("total.area").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       debugger;
      //       this.analysisData.area = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.area = "-- --";
      //     }
      //   }
      // });
      // // 人均综合能耗 综合能耗*1000/人数
      // screenQuery("total.people").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       this.analysisData.people = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.people = "-- --";
      //     }
      //     this.status.analysis = false;
      //   }
      // });
      // 人均综合能耗 综合能耗*1000/人数
      // screenQuery("electric.charge").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       this.analysisData.people = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.people = "-- --";
      //     }
      //     this.status.analysis = false;
      //   }
      // });
      // 人均综合能耗 综合能耗*1000/人数
      // screenQuery("coefficient").then((res) => {
      //   if (res.data.code == 0) {
      //     if (res.data.data.value) {
      //       this.analysisData.people = (
      //         this.censusData.value / res.data.data.value
      //       ).toFixed(2);
      //     } else {
      //       this.analysisData.people = "-- --";
      //     }
      //     this.status.analysis = false;
      //   }
      // });
    },
    // 加载能耗指标使用情况
    async loadUserd() {
      // 用电
      await getDayElecUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.electric = res.data.data.formatValue;
        }
      });
      // 用冷量
      await getDayColdUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.cold = res.data.data.formatValue;
        }
      });
      // 燃气
      await getDayAirUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.gas = res.data.data.formatValue;
        }
      });
      // 用热
      await getDiffData([this["energy.use.hot.water"]]).then((res) => {
        if (res.data.code === 0) {
          this.usedData.heat = res.data.data[this["energy.use.hot.water"]];
        }
      });
      // 用水
      await getDayWaterUse().then((res) => {
        if (res.data.code === 0) {
          this.usedData.water = res.data.data.formatValue;
        }
      });

      await getOverallCOP().then((res) => {
        if (res.data.code === 0) {
          this.usedData.cop = res.data.data.formatValue;
        }
      });

      this.status.loadUserd = false;
    },
    // getShowTypes() {
    //   getShowTypes().then((res) => {
    //     if (res.data.code === 0) {
    //       this.multipleArr = [
    //         { energyTypeId: -1, energyTypeName: "综合" },
    //         { energyTypeId: "elec", energyTypeName: "电" },
    //         { energyTypeId: "gas", energyTypeName: "燃气" }
    //         // ...res.data.data,
    //       ];
    //       this.multipleIndex = -1;
    //       this.useEnergySearch();
    //     }
    //   });
    // },
    changeMultipleIndex(multipleIndex,index) { // multipleIndex
      this.multipleIndex = multipleIndex;
      if(index == 0){
        this.loadMultiple(this.dataarr.zh.today, this.dataarr.zh.yesterday);
      }else if(index == 1){
        this.loadMultiple(this.dataarr.d.today, this.dataarr.d.yesterday);
      }else{
        let month = new Date().getMonth() + 1
        if([6,7,8,9].indexOf(month)){
          this.coolcon = 19034.9
          this.heatcon = 0
          this.gasnum = this.sum(this.rqdataArr.zlj.today)
          this.loadMultiple(this.rqdataArr.zlj.today, this.rqdataArr.zlj.yesterday);
        }else if([1,12].indexOf(month)){
          this.coolcon = 0
          this.heatcon = 15813.3
          this.gasnum = this.sum(this.rqdataArr.zrj.today)
          this.loadMultiple(this.rqdataArr.zrj.today, this.rqdataArr.zrj.yesterday);
        }else{
          this.coolcon = 0
          this.heatcon = 0
          this.gasnum = this.sum(this.rqdataArr.gdj.today)
          this.loadMultiple(this.rqdataArr.gdj.today, this.rqdataArr.gdj.yesterday);
        }
      }

      // return

      console.log('this.multipleIndex',multipleIndex,index)
      // this.status.comprehensiveData = true;
      // this.multipleIndex = multipleIndex;
      // this.useEnergySearch();
    },
    sum(arr){
      let num = 0
      arr.forEach(item=>{
        num += item
      })
      return num
    },
    // 综合能耗分析
    useEnergySearch() {
      this.useEnergyQueryAll.pointId = this.multipleIndex;
      comprehensiveData(this.useEnergyQueryAll).then((res) => {
        if (res.data.code === 0) {
          let firstData = res.data.data.first.map((res) => {
            return res.formatValue || "0";
          });
          let lastData = res.data.data.last.map((res) => {
            return res.formatValue;
          });
          this.loadMultiple(firstData, lastData);
        }
      });
    },
    monthData() {
      this.monthDataQueryYoy.pointId = this["energy.consume.yoy.point"];
      monthData(this.monthDataQueryYoy).then((res) => {
        if (res.data.code === 0) {
          this.yoyData = res.data.data;
          this.yoyData.time = dayjs()
            .subtract(4, "month")
            .subtract(1, "year")
            .format("YYYY年MM月");
        }
      });
      this.monthDataQueryQoq.pointId = this["energy.consume.yoy.point"];
      monthData(this.monthDataQueryQoq).then((res) => {
        if (res.data.code === 0) {
          this.qoqData = res.data.data;
          this.qoqData.time = dayjs().subtract(5, "month").format("YYYY年MM月");
        }
      });
    },
    async loadCenterData() {
      // 实时负荷
      await this.getMonitorRealtime();
      // 根据时间获取节能
      await this.yearEnergySaving();
      // 获取累计节费
      await this.totalSavingFee();
      // 获取累计减排
      await this.totalReduction();
      this.status.loadCenterData = false;
    },
    getMonitorRealtime() {
      // 实时负荷
      getMonitorRealtime([
        this["energy.electric.point.p"],
        this["hot.run.mode"],
      ]).then((res) => {
        if (res.data.code === 0) {
          this.getMonitorRealtimeData =
            res.data.data[this["energy.electric.point.p"]];
          this.hotData = res.data.data[this["hot.run.mode"]];
        }
      });
    },
    showFull() {
      if (this.isProjectOverview == "true") {
        this.$store.commit("mutationsHideProject");
      } else {
        this.$store.commit("mutationsShowProject");
      }
    },
    yearEnergySaving() {
      yearEnergySaving(this.yearTotalQuery).then((res) => {
        if (res.data.code == 0) {
          this.reduceData.saving = res.data.data.formatValue;
          this.status.reduceData = false;
        }
      });
      yearEnergySaving({
        ...this.yearTotalQuery,
        startTime: "2022-01-01 00:00:00",
      }).then((res) => {
        if (res.data.code == 0) {
          this.censusDataAll.value = res.data.data.formatValue;
        }
      });
    },
    totalSavingFee() {
      totalSavingFee(this.yearTotalQuery).then((res) => {
        if (res.data.code === 0) {
          this.totalSavingFeeData = res.data.data.formatValue;
        }
      });
    },
    totalReduction() {
      totalReduction(this.yearTotalQuery).then((res) => {
        if (res.data.code === 0) {
          this.totalReductionData = res.data.data.formatValue;
        }
      });
    },
    // 单位面积综合能耗
    comprehensiveArea() {
      comprehensiveArea(this.comprehensiveAreaQuery).then((res) => {
        if (res.data.code === 0) {
          this.analysisData.area = res.data.data ? res.data.data.toFixed(1) : 0;
        }
      });
    },
    // 单位面积综合能耗
    comprehensivePerson() {
      comprehensivePerson(this.comprehensiveAreaQuery).then((res) => {
        if (res.data.code === 0) {
          this.analysisData.people = res.data.data
            ? res.data.data.toFixed(1)
            : 0;
          this.status.analysis = false;
        }
      });
    },
    goColdSystem() {
      if (this.currentProjectId === "jkjd") {
        this.$router.push("/actual/cold-system-jk");
        // if (window != window.parent) {
        //   window.parent.childChangeRouter(
        //     "http://platform.hubenergy.cn:8089/#/actual/heating-system-jk"
        //   );
        // }
      } else {
        this.$router.push({
          name: "heatingSystem",
        });
      }
    },
    goHeatingSystem() {
      if (this.currentProjectId === "jkjd") {
        this.$router.push("/actual/heating-system-jk");
        // if (window != window.parent) {
        //   window.parent.childChangeRouter(
        //     "http://platform.hubenergy.cn:8089/#/actual/heating-system-jk"
        //   );
        // }
      } else {
        this.$router.push({
          name: "heatingSystem",
        });
      }
    },
  },
  computed: {
    ...mapState({
      isProjectOverview: (state) => state.isProjectOverview,
      currentProjectId: (state) => state.currentProjectId,
    }),
  },
  watch: {
    // currentProjectId() {
    //   this.getShowTypes();
    // },
  },
  mounted() {
    let monthnum = date.getMonth() + 1
    let allnum = 0
    this.nhysjn.forEach((item,index)=>{
      if(index < monthnum){
        allnum += item
      }
    })
    this.allnum = (allnum / monthnum).toFixed(1)

    // 根据Key获取的数据字典的数据
    this.multipleQuery().then((res) => {
      if (res) {
        // 赋值点
        // this.getMultipleKey()
        // 加载Echarts图表
        this.loadEcharts();
        // 综合能耗统计
        this.periodDiff();
        // 同比环比
        this.monthData();
        // 能耗指标使用情况
        this.loadUserd();
        // 综合能耗分析的类型
        // 加载中心部分数据
        this.loadCenterData();
        this.comprehensiveArea();
        this.comprehensivePerson();
      }
    });
    this.energyanalysisEchart()
  },
};
</script>

<style>
@font-face {
	font-family: BoldOTF !important;
	src: url("../../assets/fontSize/screenFontSize-Bold.otf");
}
</style>
<style lang="less" scoped>
 @import url("~@/assets/font/font.css");

@font-face {
	font-family: BoldOTF !important;
	src: url("../../assets/fontSize/screenFontSize-Bold.otf");
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer !important;
}
::-webkit-scrollbar-track {
  border-bottom: transparent;
  border-radius: 10px;
  background: transparent;
  cursor: pointer !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  display: block !important;
  cursor: pointer !important;
}
.projectOverviewJD {
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #fff;
  font-family: BoldOTF;
  &.bg1 {
    background: url("~@/assets/heatingSystem/bgimg1.png") no-repeat center;
    background-size: 100% 100%;
  }
  &.bg2 {
    background: url("~@/assets/projectOverallJD/back.png") no-repeat center;
    background-size: 100% 100%;
  }
  /deep/ .el-loading-mask {
    background-color: #1f386566;
  }
  .header {
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .h-left {
        background: rgb(11,14,20);
      width: 400px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: space-between;
      .heat-box {
        height: 30%;
        width: 100%;
        background: linear-gradient(180deg, rgba(0,28,113,0.5900) 0%, rgba(11,14,20,0.3500) 100%);
      }
    }
    .h-center {
        position: relative;
        flex: 1;
      .center-header {
        width: 100%;
        height: 99%;
        position: absolute;
        top: -2px;
      }
      .center-list {
        display: flex;
        justify-content: center;
        margin-top: 14px;
        .center-item {
          width: 153px;
          margin: 0 16px;
          .center-val {
            text-align: center;
            // font-family: DINAlternate-Bold, DINAlternate;
            font-size: 34px;
            font-weight: bold;
            color: #ffffff;
            line-height: 45px;
            font-family: BoldFont !important; 
            span {
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              line-height: 22px;
              margin-left: 5px;
            }
          }
          .center-name {
            height: 37px;
            margin-top: 4px;
            text-align: center;
            line-height: 32px;
            background: url("~@/assets/projectOverallJD/centerName.png") no-repeat
              center;
            background-size: 100% 100%;
          }
        }
      }
    }
    .h-right {
      width: 400px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: rgb(11,14,20);
      .heat-box {
        background: linear-gradient(180deg, rgba(0,39,156,0.5900) 0%, rgba(11,14,20,0.3500) 100%);
      }
    }
  }

  // 综合能耗统计
  .census {
    height: 30%;
    background: linear-gradient(
      90deg,
      rgba(4, 11, 32, 0) 0%,
      rgba(2, 4, 12, 0.3) 13%,
      rgba(0, 0, 0, 0.5) 100%
    );
    .census-body {
      height: calc(100% - 32px);
      display: flex;
      justify-content: space-between;
      padding: 0 25px;
      width: 100%;
      .census-left {
        flex: 0 0 156px;
        margin-right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .census-left-number {
          text-align: center;
          position: relative;
          bottom: -10px;
          .c-l-n-value {
            font-family: BoldFont !important; 
            font-weight: bold;
            color: #56F4FF;
            text-shadow: 0px 0px 0px #236AFF;
            font-size: 34px;
            margin-top: -6px;
          }
          .c-l-n-name {
            font-size: 14px;
            font-weight: 400;
            color: #d8d8d8;
          }
        }
        .census-left-name {
          width: 152px;
          height: 68px;
          background: url("~@/assets/projectOverallJD/综合能耗2.webp") no-repeat
            center;
          background-size: 100% 100%;
          position: relative;
          text-align: center;
          line-height: 60px;
          font-size: 14px;
          .census-left-name-img {
            width: 76px;
            height: 11px;
            background: url("~@/assets/heatingSystem/census-number-img.png")
              no-repeat center;
            background-size: 100% 100%;
            position: absolute;
            bottom: 7px;
            left: 40px;
          }
        }
      }
      .census-variety {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .census-variety-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: 63px;
          background: url('~@/assets/projectOverallJD/同比框.png');
          background-size: 100% 100%;
          background-position: 0 0;
          padding-left: 8%;
          &:first-child {
            margin-bottom: 8px;
          }
          .c-variety-item-number {
            font-size: 24px;
            font-weight: bold;
            font-family: BoldFont !important; 
            i {
              margin-right: -4px;
            }
            &.bottom {
              color: #00dbff;
            }
            &.top {
              color: #fbbb18;
            }
          }
          .c-variety-item-time {
            font-size: 14px;
            font-weight: 400;
            color: #d8d8d8;
          }
        }
      }
    }
  }
  // 单位能耗分析
  .analysis {
    height: 25%!important;
    background: linear-gradient(
      90deg,
      rgba(4, 11, 32, 0) 0%,
      rgba(2, 4, 12, 0.48) 13%,
      rgba(0, 0, 0, 0.8) 100%
    );
    .analysis-body {
      overflow-x: scroll;
      height: calc(100% - 32px);
        margin: 0 auto;
      width: 96%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .analysis-item {
        flex: 1;
        height: 90%;
        position: relative;
        background: url('~@/assets/projectOverallJD/单位背景.png');
        background-size: 100% 100%;
        background-position: 0 0;
        &:first-child {
          margin-right: 10px;
        }
        .analysis-title {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          text-align: center;
          font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }
        .analysis-item-center {
          height: calc(100% - 32px);
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-bottom: 10px;

          .analysis-left {
            width: 66px;
            height: 66px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.square {
              background: url("~@/assets/projectOverallJD/01.webp")
                no-repeat center;
              background-size: 100% 100%;
            }
            &.user {
              background: url("~@/assets/projectOverallJD/02.webp")
                no-repeat center;
              background-size: 100% 100%;
            }
          }
          .analysis-right {
            .analysis-right-num {
              font-size: 26px;
              font-weight: bold;
              color: #00dbff;
              text-shadow: 0px 0px 0px #236aff;
              font-family: BoldFont !important; 
            }
            .analysis-right-val {
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  // 智能模式
  .pattern.heat-box {
    height: 43% !important;
    flex-shrink: 0;
    min-height: 260px;
    .energyanalysisTitle{
      height: 52px;
      line-height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      margin-top: 10px;
      .logo{
        width: 52px;
        height: 52px;
        margin-right: 20px;
      }
      span{
        font-size: 26px;
        font-weight: bold;
        color: #00DBFF;
        line-height: 60px;
        text-shadow: 0px 0px 0px #236AFF;
        margin-left: 10px;
        font-family: BoldFont !important; 
        margin-right: 4px;
      }
    }
    .echartCont{
      width: 100%;
      height: calc(100% - 62px);
      transform: translateY(-15%);
      // background: #00DBFF;
      #energyanalysisEchart{
        width: 100%;
        height: 100%;
      }
    }
    
  }
  .reduce {
    height: 22%;
    flex-shrink: 0;
    // min-height: 140px;
    .reduce-body {
      height: calc(100% - 32px);
      display: flex;
      justify-content: center;
      align-items: center;
      .reduce-left {
        margin-right: 30px;
        .reduce-number {
          font-size: 34px;
          font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #00DBFF;
            line-height: 42px;
            text-shadow: 0px 0px 0px #236AFF;
          font-family: BoldFont !important; 
          span {
            margin-left: 4px;
            font-size: 16px;
            font-weight: 400;
            color: #fff;
          }
        }
        .reduce-name {
          font-size: 16px;
          color: #fff;
        }
      }
      .reduce-right {
        width: 202px;
        height: 67px;
        background: url("~@/assets/heatingSystem/reduce-bg.png") no-repeat
          center;
        background-size: 100% 100%;
        font-size: 16px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 28px;
          margin-left: 2px;
          font-weight: bold;
          font-family: BoldFont !important; 
          span {
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .zhineng{
    height: 40%;
    flex-shrink: 0;
    .pattern-body {
      height: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px 0;
      .pattern-item {
        width: 388px;
        height: calc(50% - 6px);
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        .jineneg-number{
            width: 150px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            .number{
                font-size: 24px;
                color: #FBBB18;
                font-weight: bold;
                font-family: BoldFont !important; 
                span{
                  font-family: BoldFont !important; 
                }
                &.down{
                     color: #00DBFF;
                }
            }
            .txt{
                font-size: 14px;
                margin-top: 2px;
            }
        }
        .pattern-left {
          width: 7px;
          height: 100%;
          position: absolute;
        }
        &.stop {
          background: url("~@/assets/projectOverallJD/冷.png");
          background-position: 0 0;
          background-size: 100% 100%;
          .pattern-left {
            // background: url("~@/assets/heatingSystem/pattern-left-stop.png")
            //   no-repeat center;
            background-size: 100% 100%;
          }
          .pattern-icon {
            // background: url("~@/assets/heatingSystemWebp/引导按钮.webp")
            //   no-repeat center;
            // background-size: 100% 100%;
          }
          .pattern-val span {
            color: #00dbff;
          }
          .pattern-right {
            height: 92%;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 6px;
            img {
              height: 100%;
              z-index: 2;
            }
            .pattern-right-bg {
              
            }
          }
        }
        &.start {
          cursor: pointer;
          background: url("~@/assets/projectOverallJD/热.png");
          background-position: 0 0;
          background-size: 100% 100%;
          .pattern-left {
            // background: url("~@/assets/heatingSystem/pattern-left-start.png")
            //   no-repeat center;
            background-size: 100% 100%;
          }
          // .pattern-icon {
          //   background: url("~@/assets/heatingSystemWebp/引导按钮2.webp")
          //     no-repeat center;
          //   background-size: 100% 100%;
          // }
          .pattern-val span {
            color: #fbbb18;
          }
          .pattern-right {
            height: 90%;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 8px;
            img {
              height: 100%;
              z-index: 2;
            }
            .pattern-right-bg {
             
            }
          }
        }
        .icon {
          width: 34px;
          height: 34px;
        }
        .pattern-name {
          width: 36px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          line-height: 23px;
          margin-left: 26px;
          margin-right: 12px;
        }
        .pattern-icon {
          width: 34px;
          height: 34px;
          // margin-left: 110px;
        }
        .pattern-val {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }
  .jianpai{
    height: 37%;
    flex-shrink: 0;
    .analysisStyle{
      width: 100%;
      height: calc(100% - 32px);
      display: flex;
      align-items: center;
      padding-left: 35px;
      .itemcont{
        width: 354px;
        height: 72px;
        background-image: url(~@/assets/projectOverallJD/底222.png);
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        .analysisTxt{
          display: inline-block;
          margin-left: 42px;
          height: 100%;
          line-height: 53px;
          .txt1{
            font-size: 16px;
            font-weight: 500;
            color: #69CAFF;
          }
          .txt2{
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px;
            background-image:-webkit-linear-gradient(bottom,#75BFFF,#fff); 

    -webkit-background-clip:text; 

    -webkit-text-fill-color:transparent; 
            margin-left: 8px;
          }
          .txt3{
            font-size: 26px;
            font-weight: 600;
            color: #5CF7FF;
            line-height: 37px;
            margin-left: 8px;
            font-family: BoldFont !important; 
          }
        }
      }
    }
    .seconditem{
      margin-top: 20px;
    }
  }
  .month {
    height: 80%;
    // min-height: 290px;
    .month-body {
      padding: 17px 15px 0 15px;
      .month-title {
        display: flex;
        justify-content: space-between;
        .yl-f-column-num {
          font-size: 22px;
          font-weight: bold;
          &.down {
            color: #00dbff;
          }
          &.up {
            color: #fbbb18;
          }
        }
      }
    }
    .cdm-title {
      display: flex;
      > div {
        margin-right: 20px;
      }
      .yl-f-column-num {
        font-size: 22px;
        font-weight: bold;
        &.down {
          color: #00dbff;
        }
        &.up {
          color: #fbbb18;
        }
      }
    }
  }
  .cdm {
    height: 40%;
    .cdm-body {
      padding: 17px 15px 0 15px;
    }
  }
  .floor {
    height: 25%;
    width: 100%;
    display: flex;
    .used {
      height: 100%;
      width: 48%;
    }
    .multiple {
      height: 100%;
      flex: 1;
      margin-left: 10px;
    }
  }
  .used {
    background: #021230e6;
    .heat-title {
      // width: 100%;
    }
    .used-body {
      padding: 0 1%;
      display: flex;
      align-items: center;
      justify-content: center;
      .used-item {
        width: 14%;
        height: 81%;
        margin:0 10px;
        padding-bottom: 2%;
        background: url("~@/assets/heatingSystem/used-item.png") no-repeat
          center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .item-header {
          width: 100%;
          height: 32px;
          line-height: 32px;
          padding-left: 10%;
          white-space: nowrap;
          background: linear-gradient(
            180deg,
            #2c50bf66 0%,
            rgba(11, 53, 182, 0) 100%
          );
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 6%;
        }
        .item-icon {
          width: 54%;
          margin-bottom: 4%;
          min-height: 71px;
        }
        .item-num {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          min-height: 27px;
          font-family: BoldFont !important; 
        }
      }
    }
  }
  .multiple {
    background: linear-gradient(179deg, #020e33 0%, #000000 100%);
    opacity: 0.9;
    .heat-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      font-family: BoldOTF;
      .multiple-title {
        display: flex;
        .multiple-title-item {
          height: 32px;
          padding: 0 10px;
          line-height: 32px;
          text-align: center;
          border-radius: 0px;
          border: 1px solid #3f6df8;
          font-size: 14px;
          cursor: pointer;
          &.active {
            background-color: #3f6df8;
          }
        }
      }
    }
  }
  .bgWebp {
    position: absolute;
  }
  .heat-body {
    height: calc(100% - 32px);
  }
  .yl-f-column {
    display: flex;
    flex-direction: column;
    .yl-f-column-num {
      font-size: 26px;
    }
    .yl-f-column-name {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
.heat-title {
  width: 100%;
  height: 32px;
  line-height: 30px;
  padding-left: 35px;
  background: #001E71;
  background-size: auto 100%;
  opacity: 0.9;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-image: linear-gradient(90deg, #8ba7fa, rgba(236, 6, 37, 0) 50%) 10 0;
  position: relative;
  .leftIcon{
    height: 32px;
    width: 338px;
    position: absolute;
    left: 2px;
    top: 0;
  }
  .rightIcon{
    width: 29px;
    height: 32px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.showFull {
  background: #136bba33;
  float: right;
  border: 1px solid #136bba;
  padding: 0 5px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
