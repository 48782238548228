<template>
  <projectOverviewJD bgimg="bg2" projectCode="jkjd"></projectOverviewJD>
</template>

<script>
import projectOverviewJD from "@/components/projectOverview/projectOverviewJD";
export default {
  name: "",
  data() {
    return {};
  },
  components: { projectOverviewJD },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
